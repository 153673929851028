export enum ResourceId {
  Dashboad = 'dashboad',
  Clients = 'clients',
  Applications = 'applications',
  CustomerBalances = 'customer-balances',
  Settings = 'settings',
  Keywords = 'settings:keywords',
  TrustedBanks = 'settings:trusted-banks',
  AcceptedEmployers = 'settings:accepted-employers',
  Programs = 'settings:programs',
  DuplicationRules = 'settings:duplication-rules',
  LeadProviders = 'settings:lead-providers',
  RulesManagement = 'settings:rules-management',
  ScorecardsUpload = 'settings:scorecards-upload',
}

export interface Resource {
  id: ResourceId;
  name: string;
  icon: string;
  route?: string;
}

/**
 * Class which represents a relation between a resorce and a page component
 */
export class ResourcePage {
  static map: Record<ResourceId, Resource> = {
    [ResourceId.Dashboad]: {
      id: ResourceId.Dashboad,
      name: 'Dashboard',
      icon: 'dashboard',
      route: '/dashboard',
    },
    [ResourceId.Clients]: {
      id: ResourceId.Clients,
      name: 'Clients',
      icon: 'clients',
      route: '/clients',
    },
    [ResourceId.Applications]: {
      id: ResourceId.Applications,
      name: 'Applications',
      icon: 'applications',
      route: '/advances',
    },
    [ResourceId.CustomerBalances]: {
      id: ResourceId.CustomerBalances,
      name: 'Customer Balances',
      icon: 'balances',
      route: '/balances',
    },
    [ResourceId.Settings]: {
      id: ResourceId.Settings,
      name: 'Settings',
      icon: 'settings',
    },
    [ResourceId.Keywords]: {
      id: ResourceId.Keywords,
      name: 'Keywords',
      icon: 'keywords',
      route: '/keywords',
    },
    [ResourceId.TrustedBanks]: {
      id: ResourceId.TrustedBanks,
      name: 'Trusted Banks',
      icon: 'trusted-banks',
      route: '/trusted-banks',
    },
    [ResourceId.AcceptedEmployers]: {
      id: ResourceId.AcceptedEmployers,
      name: 'Accepted Employers',
      icon: 'accepted-employers',
      route: '/accepted-employers',
    },
    [ResourceId.Programs]: {
      id: ResourceId.Programs,
      name: 'Programs',
      icon: 'programs',
      route: '/programs',
    },
    [ResourceId.DuplicationRules]: {
      id: ResourceId.DuplicationRules,
      name: 'Duplication Rules',
      icon: 'duplication-rules',
      route: '/duplication-rules',
    },
    [ResourceId.LeadProviders]: {
      id: ResourceId.LeadProviders,
      name: 'Lead Providers',
      icon: 'lead-providers',
      route: '/lead-providers',
    },
    [ResourceId.RulesManagement]: {
      id: ResourceId.RulesManagement,
      name: 'Rules Management',
      icon: 'rules-management',
      route: '/rules-management',
    },
    [ResourceId.ScorecardsUpload]: {
      id: ResourceId.ScorecardsUpload,
      name: 'Offer Calculation Parameters',
      icon: 'offer-calculation',
      route: '/scorecards-upload',
    },
  };

  /**
   * Get if a url is related to a resource
   * @param url string
   */
  static getResourceRelatedTo(url: string): Resource {
    return Object.values(ResourcePage.map).find((element) => url.includes(element.route));
  }
}
