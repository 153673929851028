import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../user.state';

/**
 * Get user info
 */
export const selectUserState = createFeatureSelector<UserState>('user');

/**
 * Get user's acronymn
 */
export const selectNameAcronimn = createSelector(selectUserState, (state: UserState): string =>
  (state.firstName && state.lastName
    ? state.firstName[0] + state.lastName[0]
    : state.email
      ? state.email[0]
      : ''
  ).toUpperCase(),
);

/**
 * Get user's permissions
 */
export const selectPermissions = createSelector(
  selectUserState,
  (state: UserState) => state.permissions,
);

/**
 * Get user's preferences
 */
export const selectPreferences = createSelector(
  selectUserState,
  (state: UserState) => state.preferences,
);
