import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';
import { Observable, Subject, iif, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { STORAGE_COUNTER_KEY } from '../constants/storage-counter';
import { ResourceId, ResourcePage } from '../enums/auth2/resources';
import {
  ActionDetail,
  Actions,
  ApplicationComponents,
  ComponentActions,
  DashboardComponents,
  UserPermissions,
} from '../enums/auth2/user-roles.enum';
import { IdentityToken, UserDetails as User, UserDetails } from '../models/auth2/user';
import { clearUser, updateUserDetails } from '../states/user/actions/user.actions';
import { selectPermissions, selectUserState } from '../states/user/selector/user.selector';
import { UserState } from '../states/user/user.state';

export interface Item {
  name: string;
  icon: string;
  route: string;
  subItems?: Item[];
}

enum IDENTITY_KEY {
  AccessToken = 'ACCESS_TOKEN',
  RefreshToken = 'REFRESH_TOKEN',
}

interface JwtPayload {
  exp: number;
}

@Injectable({
  providedIn: 'root',
})
export class Auth2Service {
  /**
   * URL base
   */
  private readonly API_BASE = `${environment.apiBaseUrl}/identity`;

  /**
   * Default page is applications
   */
  private defaultReturnUrl = ResourcePage.map[ResourceId.Applications].route;

  /**
   *
   */
  logout$: Subject<string> = new Subject();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private store: Store<UserState>,
  ) {
    this.logout$.subscribe((returnUrl: string) => this.logout(returnUrl));
  }

  /**
   * Get user session's accessToken
   */
  get accessToken() {
    return localStorage.getItem(IDENTITY_KEY.AccessToken) || null;
  }

  /**
   * Get user session's refreshToken
   */
  get refreshToken() {
    return localStorage.getItem(IDENTITY_KEY.RefreshToken) || null;
  }

  /**
   * Get user expiration date
   */
  get expirationDate(): number {
    return this.decodeJWT(this.accessToken)?.exp;
  }

  /**
   * Request user's session with userName and password
   * @param {{ userName: string; password: string }} param0 user's data
   * @returns User's session
   */
  login({ userName, password }: { userName: string; password: string }): Observable<IdentityToken> {
    const body = `userName=${userName}&password=${password}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient
      .post<IdentityToken>(`${this.API_BASE}/usertoken`, body, { headers })
      .pipe(
        tap((token) => {
          // Set necessary data to manage user session
          this.setIdentity(token);
          // Get user permissions
          return this.getUserPermissions();
        }),
      );
  }

  /**
   * Remove session values adn move the user to login page
   */
  private logout(returnUrl?: string) {
    // Remove user's data
    localStorage.removeItem(IDENTITY_KEY.AccessToken);
    localStorage.removeItem(IDENTITY_KEY.RefreshToken);
    this.store.dispatch(clearUser());
    // Redirect to login page
    this.router.navigate(['/login'], { queryParams: { returnUrl } });
  }

  /**
   * Refresh user's session
   */
  refreshSession(): Observable<IdentityToken> {
    return this.httpClient
      .get<IdentityToken>(`${this.API_BASE}/usertoken/refresh`, {
        params: { refreshToken: this.refreshToken },
      })
      .pipe(
        tap((token) => this.setIdentity(token)),
        tap(() => this.getUserPermissions()),
      );
  }

  /**
   * Get user role
   * @returns the role for the logged useer
   */
  private getUserDetails(): Observable<User> {
    return this.httpClient.get<User>(`${this.API_BASE}/details`).pipe(
      // Save user details in store
      tap((details) => this.store.dispatch(updateUserDetails(details))),
    );
  }

  /**
   * Get user info
   * @returns user info
   */
  getUser(): Observable<User> {
    return this.store
      .select(selectUserState)
      .pipe(
        switchMap((user: UserDetails) =>
          iif(() => !!user && !this.isSessionExpired(), of(user), this.getUserDetails()),
        ),
      );
  }

  /**
   * Get user permissions
   * @returns user permissions
   */
  getUserPermissions(): Observable<UserPermissions> {
    return this.store
      .select(selectPermissions)
      .pipe(
        switchMap((permissions: UserPermissions) =>
          iif(
            () => !!permissions,
            of(permissions),
            this.getUserDetails().pipe(map(({ permissions }) => permissions)),
          ),
        ),
      );
  }

  /**
   * Save in local storage the access and refresh token
   * @param {IdentityToken} identity tokens main information
   */
  private setIdentity(identity: IdentityToken) {
    if (identity?.accessToken && identity?.refreshToken) {
      localStorage.setItem(IDENTITY_KEY.AccessToken, identity.accessToken);
      localStorage.setItem(IDENTITY_KEY.RefreshToken, identity.refreshToken);
      localStorage.setItem(STORAGE_COUNTER_KEY, Date.now().toString());
    }
  }

  /**
   * Use jwtDecode function to decode jwt
   * @param {string} token jwt token
   * @returns object with data
   */
  private decodeJWT(token: string): JwtPayload {
    try {
      return jwtDecode<JwtPayload>(token);
    } catch (e) {
      // console.log('Decode Error:', e);
      return {} as JwtPayload;
    }
  }

  /**
   * Verify if current user's has session values
   * @returns
   */
  isSessionPresent(): boolean {
    return !!this.accessToken && !!this.refreshToken;
  }

  /**
   * Verify if current user's session is expired
   * @returns
   */
  isSessionExpired(): boolean {
    const expirationDate = new Date(this.expirationDate * 1000);
    if (!this.expirationDate && isNaN(expirationDate?.getTime())) {
      return true;
    }
    return expirationDate < new Date();
  }

  /**
   * Verify if current user's session is valid
   * @returns
   */
  isValidSession(): boolean {
    return this.isSessionPresent() && !this.isSessionExpired();
  }

  /**
   * Navigates to default page
   */
  navigateToReturnUrl(returnUrl?: string) {
    this.router.navigateByUrl(returnUrl || this.defaultReturnUrl);
  }

  /**
   * Get menu based on user's resource access
   * @param param0
   * @returns
   */
  private getMenuList({ resources }: UserPermissions): Item[] {
    let itemList: Record<string, Item> = {};

    Object.keys(resources).forEach((resourceId: ResourceId) => {
      const [item, subItem] = resourceId.split(':');
      const canAccess = this.hasPermissionsToResource(resources[resourceId], 'read');

      if (item && subItem) {
        const parent = itemList[item];
        const child = { ...ResourcePage.map[resourceId] } as Item;
        if (canAccess) {
          parent['subItems'] = [...(parent['subItems'] || []), child];
        }
        return;
      }

      canAccess && (itemList[item] = { ...ResourcePage.map[item] } as Item);
    }, []);

    return Object.values(itemList);
  }

  /**
   * Get menu
   * @returns
   */
  getMenu(): Observable<Item[]> {
    return this.getUserPermissions().pipe(map((permissions) => this.getMenuList(permissions)));
  }

  /**
   * Verify if user is authenticated and can access to a specific url
   * @param url the url requested
   * @returns whether the user can access
   */
  canAccessToUrl(url: string): Observable<boolean> {
    const requested: ResourceId = ResourcePage.getResourceRelatedTo(url)?.id;
    return this.canAccessToResource(requested).pipe(
      tap((hasAccess) => !hasAccess && this.router.navigateByUrl('/not-authorized')),
    );
  }

  /**
   * Given a resource Id, get if user has the specified action
   * @param resourceId resourceId
   * @param {keyof Actions} actionProp action string: 'create', 'read', 'update', 'delete'
   * @returns whether the user has access
   */
  canAccessToResource(
    resourceId: ResourceId,
    actionProp: keyof Actions = 'read',
  ): Observable<boolean> {
    return this.getUserPermissions().pipe(
      map(({ resources }) => this.hasPermissionsToResource(resources[resourceId], actionProp)),
    );
  }

  /**
   * Given a resource Id, get if user has the specified action
   * @param resourceId resourceId
   * @param {keyof Actions} actionProp action string: 'create', 'read', 'update', 'delete'
   * @returns whether the user has access
   */
  canAccessToResourceComponent(
    resourceId: ResourceId.Dashboad | ResourceId.Applications,
    component: DashboardComponents | ApplicationComponents,
    actionProp: keyof Actions = 'read',
  ): Observable<boolean> {
    return this.getUserPermissions().pipe(
      map(({ resources }) =>
        this.hasPermissionsToResourceComponent(resources[resourceId], component, actionProp),
      ),
    );
  }

  /**
   * Review if the resource's permissions delivered has the action specified
   * @param resourcePermissions
   * @param {keyof Actions} actionProp action string: 'create', 'read', 'update', 'delete'
   * @returns
   */
  private hasPermissionsToResource(
    resourcePermissions: Actions | (Actions & ComponentActions<DashboardComponents>),
    actionProp: keyof Actions,
  ) {
    const action: boolean | ActionDetail = resourcePermissions[actionProp] || false;
    if (typeof action === 'boolean') {
      return action;
    } else {
      return action.all;
    }
  }

  /**
   * Review if the resource's permissions delivered has the action specified
   * @param resourcePermissions
   * @param {keyof Actions} actionProp action string: 'create', 'read', 'update', 'delete'
   * @returns
   */
  private hasPermissionsToResourceComponent(
    resourcePermissions:
      | (Actions & ComponentActions<DashboardComponents>)
      | (Actions & ComponentActions<ApplicationComponents>),
    component: DashboardComponents | ApplicationComponents,
    actionProp: keyof Actions,
  ) {
    // if actionProp is in key of Actions then do the next, else searhc for the key
    const action: boolean | ActionDetail =
      resourcePermissions['components']?.[component]?.[actionProp] || false;
    if (typeof action === 'boolean') {
      return action;
    } else {
      return action.all;
    }
  }
}
