import { createAction, props } from '@ngrx/store';
import { UserPermissions, UserRoles } from 'src/app/enums/auth2/user-roles.enum';
import { UserPreferences } from '../user.state';

export const setUser = createAction(
  '[User] Set user data',
  props<{
    firstName: string;
    lastName: string;
    email: string;
    thumbnail?: string;
    role: UserRoles;
    permissions: UserPermissions;
    preferences: UserPreferences;
  }>(),
);

export const updateUserDetails = createAction(
  '[User] Update user details',
  props<{
    firstName: string;
    lastName: string;
    email: string;
    thumbnail?: string;
    role: UserRoles;
    permissions: UserPermissions;
  }>(),
);

export const updateUserThemePreference = createAction(
  '[User] Update user preferences',
  props<{ isDarkTheme: boolean }>(),
);

export const clearUser = createAction('[User] Clear');
